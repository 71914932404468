<template>
    <div>
        <v-row>
            <v-col class="text-center">
                <v-avatar color="primary" size="200">
                    <img src="/images/头像.200179e4.jpg" alt="zhanghuan">
                </v-avatar>
            </v-col>
        </v-row>
        <v-row class="mt-10">
            <v-col md="2" sm="6" class="pa-1">
                <v-card flat min-width="150">
                    <v-card-text class="text-md-center pl-14">
                        <p>位置：上海</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="2" sm="6" class="pa-1">
                <v-card flat min-width="150">
                    <v-card-text class="text-md-center pl-14">
                        <p>学历：本科</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="2" sm="6" class="pa-1">
                <v-card flat min-width="150">
                    <v-card-text class="text-md-center pl-14">
                        <p>星座：水瓶</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="2" sm="6" class="pa-1">
                <v-card flat min-width="150">
                    <v-card-text class="text-md-center pl-14">
                        <p>血型：O</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="2" sm="6" class="pa-1">
                <v-card flat min-width="150">
                    <v-card-text class="text-md-center pl-14">
                        <p>身高：175</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="2" sm="6" class="pa-1">
                <v-card flat min-width="150">
                    <v-card-text class="text-md-center pl-14">
                        <p>体重：63</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-card flat outlined class="mt-16">
            <v-card-title>IT技能</v-card-title>
            <v-card-text>
                <v-progress-linear value="91" height="25" color="deep-purple lighten-2">
                    <strong class="white--text">JAVA</strong>
                </v-progress-linear>
                <v-progress-linear value="70" height="25" color="grey lighten-1" class="mt-1">
                    <strong class="white--text">PYTHON</strong>
                </v-progress-linear>
                <v-progress-linear value="70" height="25" color="indigo lighten-2" class="mt-1">
                    <strong class="white--text">GO</strong>
                </v-progress-linear>
                <v-progress-linear value="70" height="25" color="grey lighten-1" class="mt-1">
                    <strong class="white--text">PHP</strong>
                </v-progress-linear>
                <v-progress-linear value="90" height="25" color="blue lighten-3" class="mt-1">
                    <strong class="white--text">ELEMENT</strong>
                </v-progress-linear>
                <v-progress-linear value="90" height="25" color="grey lighten-1" class="mt-1">
                    <strong class="white--text">UniApp</strong>
                </v-progress-linear>
                <v-progress-linear value="85" height="25" color="purple lighten-3" class="mt-1 mb-5">
                    <strong class="white--text">VUETIFY</strong>
                </v-progress-linear>
            </v-card-text>
        </v-card>
        <v-card flat outlined class="mt-16">
            <v-card-title>兴趣爱好</v-card-title>
            <v-card-text>
                <v-row class="mb-5">
                    <v-col md="3" sm="12" xs="12">
                        <v-card class="mx-auto" max-width="344" min-width="200" shaped outlined color="grey">
                            <v-card-text>
                                <p class="display-1 white--text">
                                    编码
                                </p>
                                <div class="white--text">
                                    创造世界，其乐无穷
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" sm="12">
                        <v-card class="mx-auto" max-width="344" min-width="200" shaped outlined color="brown lighten-4">
                            <v-card-text>
                                <p class="display-1 white--text">
                                    跑步
                                </p>
                                <div class="white--text">
                                    山重水复疑无路，柳暗花明又一村
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" sm="12">
                        <v-card class="mx-auto" max-width="344" min-width="200" shaped outlined color="grey">
                            <v-card-text>
                                <p class="display-1 white--text">
                                    骑行
                                </p>
                                <div class="white--text">
                                    重要的不仅是目的地，而是沿途的风景和自由，一起看风景的人
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" sm="12">
                        <v-card class="mx-auto" max-width="344" min-width="200" shaped outlined color="indigo lighten-3">
                            <v-card-text>
                                <p class="display-1 white--text">
                                    音乐
                                </p>
                                <div class="white--text">
                                    如展翅欲飞的蝴蝶，扑闪着灵动的翅膀，清亮亮的流淌着，又如塞外悠远的天空
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" sm="12">
                        <v-card class="mx-auto" max-width="344" min-width="200" shaped outlined color="grey">
                            <v-card-text>
                                <p class="display-1 white--text">
                                    阅读
                                </p>
                                <div class="white--text">
                                    和有趣的灵魂对话
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" sm="12">
                        <v-card class="mx-auto" max-width="344" min-width="200" shaped outlined color="grey lighten-1">
                            <v-card-text>
                                <p class="display-1 white--text">
                                    医学
                                </p>
                                <div class="white--text">
                                    探索未知的世界
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>
            </v-card-text>
        </v-card>
        <v-card flat outlined class="mt-16">
            <v-card-title>开源项目</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-card flat outlined class="rounded-lg mt-2" min-width="200">
                            <v-card-text>
                                <p class="font-weight-black">定时任务</p>
                                <a href="https://gitee.com/zhanghuan1991/scheduler_backend"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/scheduler_backend</a>
                                <br>
                                <a href="https://gitee.com/zhanghuan1991/scheduler_front"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/scheduler_front</a>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat outlined class="rounded-lg mt-2"  min-width="200">
                            <v-card-text>
                                <p class="font-weight-black">题库</p>
                                <a href="https://gitee.com/zhanghuan1991/practice"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/practice</a>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat outlined class="rounded-lg mt-2"  min-width="200">
                            <v-card-text>
                                <p class="font-weight-black">商城</p>
                                <a href="https://gitee.com/zhanghuan1991/back_end_of_mall"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/back_end_of_mall</a>
                                <br>
                                <a href="https://gitee.com/zhanghuan1991/front_end_of_mall"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/front_end_of_mall</a>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card flat outlined class="rounded-lg mt-2" min-width="200">
                            <v-card-text>
                                <p class="font-weight-black">简历管理平台</p>
                                <a href="https://gitee.com/zhanghuan1991/resume-platform-backend"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/resume-platform-backend</a>
                                <br>
                                <a href="https://gitee.com/zhanghuan1991/resume-platform-front"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/resume-platform-front</a>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat outlined class="rounded-lg mt-2"  min-width="200">
                            <v-card-text>
                                <p class="font-weight-black">Linux工具（Go语言）</p>
                                <a href="https://gitee.com/zhanghuan1991/linux-tools"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/linux-tools</a>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat outlined class="rounded-lg mt-2"  min-width="200">
                            <v-card-text>
                                <p class="font-weight-black">第二杯半价（相亲平台）</p>
                                <a href="https://gitee.com/zhanghuan1991/half-price-of-the-second-cup-backend"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/half-price-of-the-second-cup-backend</a>
                                <br>
                                <a href="https://gitee.com/zhanghuan1991/half-price-of-the-second-cup-front"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/half-price-of-the-second-cup-front</a>
                                <br>
                                <a href="https://gitee.com/zhanghuan1991/second-cup-half-price-applet"
                                    class="blue-grey--text">https://gitee.com/zhanghuan1991/second-cup-half-price-applet</a>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>


            </v-card-text>
        </v-card>
        <v-card flat outlined class="mt-16 mb-16">
            <v-card-title>技术博客</v-card-title>
            <v-card-text>
                <a href="https://sylvesterzhang.github.io/book/"
                    class="blue-grey--text">https://sylvesterzhang.github.io/book/</a>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'

    export default {
        name: 'Home',
        components: {
            //HelloWorld
        },
        methods:{

        },
        created(){
            this.$emit("hideBackToTop")
        }
    }
</script>